import {RomanItem} from '@/models/Livres/RomanItem';
import {categoryTypes} from '@/config/CategoryType';

export const romans: any = {
    name: 'Romans',
    type: categoryTypes.LIVRES_TYPE,
    // même que le nom du fichier
    category: 'romans',
    title: 'Romans',
    className: RomanItem,
    // nom de la bdd utilisée
    rootLink: '/livres/romans',
    color: 'rgb(190,227,219)',
    phrases: {
        feminin: false,
        noRate: 'Non lu',
        singulier: 'roman',
        pluriel: 'romans',
        deSingulier: 'du roman',
        dePluriel: 'des romans',
        leSingulier: 'le roman',
        lePluriel: 'les romans',
        unSingulier: 'un roman',
        shown: 'roman(s)',
    },
    // type de statistique
    typeStat: 'livres',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        // à partir d'ici : 6 max
        auteur: true,
        editeur: true,
        format: true,
        genre: true,
        nbrTome: true,
        nbrTomeLu: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        editeurList: true,
        genreList: true,
        formatList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
