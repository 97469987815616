import {AutresLivresItem} from '@/models/Livres/AutresLivresItem';
import {categoryTypes} from '@/config/CategoryType';

export const autresLivres: any = {
    name: 'Autres livres',
    type: categoryTypes.LIVRES_TYPE,
    // même que le nom du fichier
    category: 'autresLivres',
    title: 'Autres livres',
    className: AutresLivresItem,
    // nom de la bdd utilisée
    rootLink: '/livres/autres',
    color: 'rgb(198,218,191)',
    phrases: {
        feminin: false,
        noRate: 'Non lu',
        singulier: 'livre',
        pluriel: 'livres',
        deSingulier: 'du livre',
        dePluriel: 'des livres',
        leSingulier: 'le livre',
        lePluriel: 'les livres',
        unSingulier: 'un livre',
        shown: 'livre(s)',
    },
    // type de statistique
    typeStat: 'livres',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        // à partir d'ici : 6 max
        dessinateur: true,
        scenariste: true,
        editeur: true,
        genre: true,
        nbrTome: true,
        nbrTomeLu: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        genreList: true,
        editeurSearch: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
