export const v11: any = {
    version: '1.1',
    subversion: [
        {
            version: '1.1.3',
            date: '09/02/2020',
            content: [
                'Résolution d\'un problème sur l\'affichage des saisons et épisodes en cours de visionnage dans la catégorie "Vidéos > Séries".',
                'Optimisation du code.',
            ],
        },
        {
            version: '1.1.2',
            date: '26/01/2020',
            content: [
                'Résolution d\'un bug au niveau des filtres.',
                'Résolution d\'un bug au niveau de la génération des liens manga-news.com.',
                'Optimisation du code.',
            ],
        },
        {
            version: '1.1.1',
            date: '21/12/2019',
            content: [
                'Le temps de chargement de la collection d\'un autre membre divisé par deux.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.1.0',
            date: '19/12/2019',
            content: [
                'Nouveau système d\'authentification maintenant basé sur nos serveurs (précédemment sur Google Firebase).',
                'Ajout de la double authentification (voir dans Mon profil -> Authentification).',
                'Connection simplifiée via un compte Google (voir dans Mon profil -> Identité fédérée).',
                'Possibilité de gérer les connexions sur différents appareils (voir dans Mon profil -> Sessions).',
                'Restructuration de la page d\'accueil.',
                'Ajout d\'un graphique sur sa collection sur la page d\'accueil.',
            ],
        },
    ],
};
