export const v16: any = {
    version: '1.6',
    subversion: [
        {
            version: '1.6.3',
            date: '18/10/2020',
            content: [
                'Correction de bugs du système de filtre et optimisation.',
                'Ajout du logo de la Xbox Series X/S.',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.6.2',
            date: '15/09/2020',
            content: [
                'Dans la catégorie "Consoles & Accessoires", ajout des fabricants d\'accessoires.',
                'Correction du bug d\'affichage de la liste des contructeurs et des éditions dans les filtres.',
                'Correction d\'orthographe dans la catégorie consoles & accessoires.',
                'Dans la catégorie "Jeux de société", suppression de la statistique "Terminé".',
                'Bug sur certains filtres (notamment "Extension") résolu.',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.6.1',
            date: '30/08/2020',
            content: [
                'Ajout de "Etats-unis" dans les pays d\'origine.',
                'Correction du bug d\'affichage de la largeur des previews d\'images.',
                'Correction de bugs d\'affichage du visualisateur.',
            ],
        },
        {
            version: '1.6.0',
            date: '28/08/2020',
            content: [
                'Ajout d\'une nouvelle catégorie "Consoles et Accessoires".',
                'Les filtres par défauts n\'affichent que les objets dans notre collection (Collection, Prêté, En vente). Bien sûr c\'est modifiable.',
                'L\'url change en fonction des filtres choisis, ce qui permet d\'avoir des liens personnalisés. Un bouton pour copier les liens a été ajouté.',
                'Dans l\'encadré "INFORMATIONS" de la catégorie sélectionnée, les statistiques se mettent à jour en fonction des filtres sélectionnés.',
                'Il est maintenant possible dans le champ "Oeuvre Originale" (pour les catégories "Artbooks" et "Goodies"), ' +
                'de sélectionner un item de sa collection et de le lier par un lien.',
                'Résolution du bug d\'affichage des images en grand : mise en place d\'un visualisateur fait maison pour une meilleure maintenance.',
                'Les miniatures des images ne débordent plus quand elles sont trop grandes.',
                'Amélioration des exports CSV.',
                'Ajout de l\'éditeur "Bloomsbury" dans la catégory "Roman".',
                'Optimisation et nettoyage dans le code et les dépendances.',
            ],
        },
    ],
};
