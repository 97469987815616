export class ItemFieldsConfig {
    /**
     * Liste de tous les élements pouvant être affiché dans un item
     * @return any
     */
    public static fields: any = {
        title: {
            label: 'Titre',
            key: 'title',
        },
        dessinateur: {
            label: 'Dessinateur',
            key: 'dessinateur',
        },
        auteur: {
            label: 'Auteur',
            key: 'auteur',
        },
        artiste: {
            label: 'Artiste / Groupe',
            key: 'artiste',
        },
        scenariste: {
            label: 'Scénariste',
            key: 'scenariste',
        },
        realisateur: {
            label: 'Réalisateur',
            key: 'realisateur',
        },
        oeuvre: {
            label: 'Oeuvre originale',
            key: 'oeuvre',
        },
        console: {
            label: 'Console',
            key: 'console',
        },
        constructeur: {
            label: 'Constructeur',
            key: 'constructeur',
        },
        edition: {
            label: 'Édition',
            key: 'edition',
        },
        studio: {
            label: 'Studio',
            key: 'studio',
        },
        fabricant: {
            label: 'Fabricant',
            key: 'fabricant',
        },
        label: {
            label: 'Label',
            key: 'label',
        },
        editeur: {
            label: 'Editeur',
            key: 'editeur',
        },
        developpeur: {
            label: 'Développeur',
            key: 'developpeur',
        },
        prix: {
            label: 'Prix',
            key: 'prix',
        },
        minPlayer: {
            label: 'Nombre de joueur',
            key: 'minPlayer',
        },
        tempsPartie: {
            label: 'Temps d\'une partie',
            key: 'tempsPartie',
        },
        type: {
            label: 'Type',
            key: 'type',
        },
        releaseDate: {
            label: 'Date de sortie',
            key: 'releaseDate',
        },
        format: {
            label: 'Format',
            key: 'format',
        },
        support: {
            label: 'Support',
            key: 'support',
        },
        genre: {
            label: 'Genre',
            key: 'genre',
        },
        defaut: {
            label: 'Défaut',
            key: 'defaut',
        },
        nbrTome: {
            label: 'Tomes',
            key: 'nbrTome',
        },
        nbrTomeLu: {
            label: 'Lu',
            key: 'nbrTomeLu',
        },
        endDate: {
            label: 'Terminé le',
            key: 'endDate',
        },
        note: {
            label: 'Note',
            key: 'note',
        },
        comment: {
            label: 'Commentaire',
            key: 'comment',
        },
        externalLink: {
            label: '',
            key: 'externalLink',
        },
        statut: {
            label: 'Statut',
            key: 'statut',
            tagType: true,
        },
        etat: {
            label: 'État',
            key: 'etat',
            tagType: true,
        },
        langue: {
            label: 'Langue',
            key: 'langue',
            tagType: true,
        },
        origine: {
            label: 'Pays d\'origine',
            key: 'origine',
            tagType: true,
        },
        dematerialise: {
            label: '',
            key: 'dematerialise',
            tagType: true,
        },
        extension: {
            label: '',
            key: 'extension',
            tagType: true,
        },
    };

    /**
     * retourne les colonnes à afficher dans le tableau d'item
     * @return object[]
     */
    public static tableItemsFields(): any {
        return [
            this.fields.title,
            this.fields.oeuvre,
            this.fields.console,
            this.fields.artiste,
            this.fields.constructeur,
            this.fields.edition,
            this.fields.studio,
            this.fields.fabricant,
            this.fields.label,
            this.fields.editeur,
            this.fields.developpeur,
            this.fields.minPlayer,
            this.fields.tempsPartie,
            this.fields.prix,
            this.fields.type,
            this.fields.format,
            this.fields.support,
            this.fields.nbrTome,
            this.fields.nbrTomeLu,
            this.fields.note,
            this.fields.statut,
            this.fields.etat,
            this.fields.langue,
            this.fields.origine,
        ];
    }

    /**
     * retourne les labels des en-têtes du tableau
     * @return any
     */
    public static tableHeadExportCSV(): any {
        const list = this.fields;
        list.category = {
            label: 'Catégorie',
        };
        list.client = {
            label: 'Client',
        };
        list.client = {
            label: 'Client',
        };
        list.createdAt = {
            label: 'Crée le',
        };
        list.updatedAt = {
            label: 'Mis à jour le',
        };
        list.dematerialise = {
            label: 'Dématérialisé',
        };
        list.extension = {
            label: 'Extension',
        };
        list.tomes = {
            label: 'Tomes',
        };
        list.tomesLu = {
            label: 'Lu',
        };
        list.vod = {
            label: 'VOD',
        };
        list.saison = {
            label: 'Saison',
        };
        list.episode = {
            label: 'Episode',
        };

        list.minPlayer.label = 'Nbr joueur min';

        list.maxPlayer = {
            label: 'Nbr joueur max',
        };

        return list;
    }
}
