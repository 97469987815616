import {Component, Prop, Watch} from 'vue-property-decorator';
import {Bar} from 'vue-chartjs/legacy';
import { BaseScript } from '@/components/BaseScript';
import {
    Chart as ChartJS,
    Tooltip,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js';

ChartJS.register(Tooltip, BarElement, CategoryScale, LinearScale);

interface ChartDataInterface {
    datasets: Record<string, any>[];
    labels: string[];
}

// @ts-ignore
@Component({
    name: 'GlobalStatistique',
    components: {
        Bar
    }
})
export default class GlobalStatistique extends BaseScript {
    @Prop(Array) private readonly data!: number[];
    @Prop(Array) private readonly labels!: string[];
    @Prop(Array) private readonly colors!: string[];

    private chartData: ChartDataInterface = {
        datasets: [{}],
        labels: [],
    }

    private chartOptions = {
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    }

    private styles = {
        height: '530px',
    }

    /**
     * récupérer les données pour le graphique des statistiques
     *
     * @private
     * @return {void}
     */
    // @ts-ignore
    @Watch('data')
    private generateStatistiqueData(): void {
        this.chartData = {
            datasets: [{
                data: this.data,
                backgroundColor: this.colors,
                borderWidth: 5,
            }],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: this.labels,
        };
    }
}
