import {SeriesItem} from '@/models/Videos/SeriesItem';
import {categoryTypes} from '@/config/CategoryType';

export const series: any = {
    name: 'Séries',
    type: categoryTypes.VIDEOS_TYPE,
    // même que le nom du fichier
    category: 'series',
    title: 'Séries',
    className: SeriesItem,
    // nom de la bdd utilisée
    rootLink: '/videos/series',
    color: 'rgb(255,22,84)',
    phrases: {
        feminin: false,
        noRate: 'Non vu',
        singulier: 'série',
        pluriel: 'séries',
        deSingulier: 'de la série',
        dePluriel: 'des séries',
        leSingulier: 'la série',
        lePluriel: 'les séries',
        unSingulier: 'une série',
        shown: 'série(s)',
    },
    // type de statistique
    typeStat: 'basic',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        realisateur: true,
        support: true,
        releaseDate: true,
        studio: true,
        genre: true,
        endDate: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        realisateur: true,
        studio: true,
        endDateList: true,
        genreList: true,
        supportList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
