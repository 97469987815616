
import {Component, Prop, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Common from '@/services/Common';
import {BaseScript} from '@/components/BaseScript';
import {config} from '@/config/CategoryConfig';

// modules
const general = namespace('general');


@Component({
    name: 'TableUpdate',
})
export default class TableLastUpdate extends BaseScript {
    // actions
    @general.Action('setChangeLog') private setChangeLog!: any;

    // getters
    @general.Getter('getChangeLog') private readonly getChangeLog!: any;

    @Prop(String) private readonly type!: string;

    private commonFunc: any = Common; // init des fonctions transverse
    private changeLogList: any = []; // tableau des 10 dernières mises à jour
    private isVisitor: boolean = Common.getIsVisitor(); // récupère la donnée qui nous dit si on est sur un profil en tant que visiteur
    private userUrl: string = ''; // utilisé pour les lien changelog
    private categoryConf: any = config;

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        // si on est visiteur, on récupére 'user' pour le lien des changelogs
        if (this.isVisitor) {
            this.userUrl = 'user';
        }
    }

    /**
     * Récupére les 10 derniers logs lorsqu'un changement dans le store est détecté.
     *
     * @private
     * @return {void}
     */
    @Watch('getChangeLog')
    private updateChangeLogList(): void {
        if (this.type === 'all') {
            this.changeLogList = this.getChangeLog;
            return;
        }

        this.changeLogList = this.getChangeLog.filter((item: any) => {
            if (item.type === this.type) {
                return item;
            }
        });
    }
}
