export const v15: any = {
    version: '1.5',
    subversion: [
        {
            version: '1.5.2',
            date: '23/08/2020',
            content: [
                'Ajout du genre "Tranche de vie" dans les catégories "Livres" (hors Autres Livres) et "Vidéos".',
                'Ajout de l\'éditeur "Shûeisha (JP)" dans la catégorie "Artbook".',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.5.1',
            date: '23/07/2020',
            content: [
                'Ajout des genres "Rail-Shooter", "Party-game", "Puzzle" dans la catégorie "Jeux vidéo".',
                'Ajout du statut "Arrêt de commercialisation" pour les livres.',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.5.0',
            date: '28/06/2020',
            content: [
                'Refonte totale de la page d\'accueil.',
                'Ajout de statistiques sur la page d\'accueil.',
                'Ajout d\'icônes sur le site.',
                'Suppression définitive du bloc note.',
                'Possibilité d\'afficher la liste sous forme de tableau.',
                'Ajout d\'un champs dans l\'espace utilisateur permetant de selectionner l\'affichage par defaut de sa liste d\'items.',
                'Ajout du tag "Extension" dans les catégories "Jeux vidéo" et "Jeux de société".',
                'Résolution d\'un bug avec le tag "Dematerialisé" sur un item, qui continuait à s\'afficher même sur "Non".',
                'Amélioration de l\'affichage des notes.',
                'Affichage des logos des jeux vidéo.',
                'Ajout de la "Playstation 5" et de la "Xbox Series X" dans les jeux vidéo.',
                'Résolution d\'un bug d\'affichage quand la note était sur 0.',
                'Ajout des filtres "Etats" et "Status" sur certaines catégories où ça ne s\'affichait pas.',
                'Amélioration de certaines input.',
                'Amélioration de l\'affichage du détail des mises à jour.',
                'Nettoyage dans le code et les dépendances.',
            ],
        },
    ],
};
