import {ArtbookItem} from '@/models/Livres/ArtbookItem';
import {categoryTypes} from '@/config/CategoryType';

export const artbook: any = {
    name: 'Artbooks',
    type: categoryTypes.LIVRES_TYPE,
    // même que le nom du fichier
    category: 'artbook',
    title: 'Artbooks',
    className: ArtbookItem,
    // nom de la bdd utilisée
    rootLink: '/livres/artbooks',
    color: 'rgb(243,233,210)',
    phrases: {
        feminin: false,
        noRate: 'Non lu',
        singulier: 'artbook',
        pluriel: 'artbooks',
        deSingulier: 'de l\'artbook',
        dePluriel: 'des artbooks',
        leSingulier: 'l\'artbook',
        lePluriel: 'les artbooks',
        unSingulier: 'un artbook',
        shown: 'artbook(s)',
    },
    // type de statistique
    typeStat: 'livres',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        // à partir d'ici : 6 max
        dessinateur: true,
        oeuvre: true,
        editeur: true,
        genre: true,
        nbrTome: true,
        nbrTomeLu: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        editeurList: true,
        genreList: true,
        langueList: true,
        oeuvre: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
