import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {config} from '@/config/CategoryConfig';
import {BaseScript} from '@/components/BaseScript';
import Common from '@/services/Common';

// modules
const general = namespace('general');
const category = namespace('category');

@Component({
    name: 'SearchData',
})
export default class SearchData extends BaseScript {

    private isVisitor: boolean = Common.getIsVisitor();
    private itemsList: object[] = [];
    private selectedItem: any = null;
    private userUrl: string = '';
    private config: any = config;
    private searchText: string = '';
    private saveSearchString: string = ''; // permet de save les 3 premiers caractères utilisés à la recherche.
    private noOptionMsg: string = this.commonStrings.selectNoOptionMinChar(3);

    // actions
    @category.Action('setOwnItemsFullList') private readonly setOwnItemsFullList!: any;

    // getters
    @category.Getter('getOwnItemsFullList') private readonly getOwnItemsFullList!: any;

    constructor() {
        super();
    }

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {

        if (this.isVisitor) {
            this.userUrl = 'user';
        }
    }

    /**
     * Permet de récupérer la liste des items. Nécessite 3 caractère minimum.
     *
     * @async
     * @private
     * @return { Promise<void> }
     */
    @Watch('searchText')
    private async initData(): Promise<void> {
        // on vérifie que l'on ait bien au moins les 3 premiers caractères, sinon on affiche le message d'erreur.
        if (this.searchText.length < 3) {
            this.itemsList = [];
            this.noOptionMsg = this.commonStrings.selectNoOptionMinChar(3);
            return;
        }

        // on récupére les 3 premiers caractère de l'input
        const tmpSearch: string = this.searchText.slice(0, 3);

        // puis on vérifie si il sont différent de ceux utilisés lors de la précédente requête
        if (tmpSearch === this.saveSearchString) {
            return;
        }

        // on récupére les 3 premiers caractères pour les sauvegarder
        this.saveSearchString = tmpSearch;

        this.noOptionMsg = this.commonStrings.selectNoOption;

        await this.setOwnItemsFullList({
            title: {
                $exists: true,
                $regex: '(?i)' + this.saveSearchString,
            },
        });
        this.itemsList = this.getOwnItemsFullList;
    }

    /**
     * Redirige vers le profile de l'utilisateur sélectionné
     *
     * @return {void}
     */
    @Watch('selectedItem', {deep: true})
    private goToItem(): void {
        // @ts-ignore
        this.$router.push({
            name: 'Refresh',
            params: {
                username: this.$route.params.username,
                path: this.userUrl + this.config[this.selectedItem.category].name,
                // @ts-ignore
                searchGlobalProp: {
                    title: this.selectedItem.title,
                },
            },
        });
    }
}
