import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import UserList from '@/components/ShareCollections/UserList/UserList.vue';
import SearchData from '@/components/NavBar/SearchData/SearchData.vue';
import ModalMangaNewsPlanning from '@/components/ModalMangaNewsPlanning/ModalMangaNewsPlanning.vue';
import ModalReportBug from '@/components/ModalReportBug/ModalReportBug.vue';
import {config} from '@/config/CategoryConfig';
import {BaseScript} from '@/components/BaseScript';
import Common from '@/services/Common';

// modules
const general = namespace('general');
const category = namespace('category');

@Component({
    name: 'NavBar',
    components: {
        UserList,
        SearchData,
        ModalMangaNewsPlanning,
        ModalReportBug,
    },
})
export default class NavBar extends BaseScript {
    private config: any = config;
    private userUrl: string = ''; // utilisé pour afficher les bon lien dans le menu si on visite le profile de quelqu'un d'autre
    private isVisitor: boolean = Common.getIsVisitor();
    private visitingUsername: string = ''; // le nom de l'utilisateur que l'on visite
    private mnPlanningEnable: boolean = false; // Si le planning Manga-news.com est activé

    // actions
    @general.Action('updateUser') private readonly updateUser!: any;
    @general.Action('setLoadingStatus') private readonly setLoadingStatus!: any;
    @category.Action('setSearchData') private readonly setSearchData!: any;

    // getters
    @general.Getter('getUserInfos') private readonly getUserInfos!: any;

    constructor() {
        super();
    }

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        // si on visite un autre profile, on récupére le nom d'utilisateur et on récupére la base de url pour les liens.
        if (this.isVisitor) {
            this.visitingUsername = this.$route.params.username;
            this.userUrl = '/user/' + this.visitingUsername;
        }

        this.checkMangaNewsAccount();
    }

    /**
     * @private
     * @return {void}
     */
    @Watch('getUserInfos')
    private changeGetUserInfos() {
        this.checkMangaNewsAccount();
    }

    /**
     * Permet de vérifier si on a activé le planning manga-news.com
     *
     * @private
     * @return {void}
     */
    private checkMangaNewsAccount(): void {
        if (Common.checkIsNotUndifined(this.getUserInfos.tokenParsed) &&
            Common.checkIsNotUndifined(this.getUserInfos.tokenParsed.accountManganews)) {
            this.mnPlanningEnable = Boolean(this.getUserInfos.tokenParsed.accountManganews.replace(/ /g, ''));
        }
    }

    /**
     * Quand on quitte la collection de quelqu'un d'autre, cette page permet de tout réinitialiser pour afficher
     * ses propres données correctement.
     *
     * @private
     * @return {void}
     */
    private backHome(): void {
        this.$router.push('/refresh');
    }
}
