import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {BaseScript} from '@/components/BaseScript';

const general = namespace('general');

// @ts-ignore
@Component({
    name: 'Loading',
})
export default class Loading extends BaseScript {
    @general.Getter('getLoadingStatus') private readonly getLoadingStatus!: boolean;

    constructor() {
        super();
    }
}
