import {Component, Watch} from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';
import {CategoryFunctions} from '@/services/CategoryFunctions';
import Common from '@/services/Common';
import {namespace} from 'vuex-class';

const general = namespace('general');

@Component({
    name: 'ModalMangaNewsPlanning',
})
export default class ModalMangaNewsPlanning extends BaseScript {
    private planningList: any[] = [];
    private error: boolean = false;
    private usernameMn: string = '';

    // getters
    @general.Getter('getUserInfos') private readonly getUserInfos!: any;

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        this.checkMangaNewsAccount();
    }

    /**
     * @private
     * @return {void}
     */
    @Watch('getUserInfos')
    private changeGetUserInfos(): void {
        this.checkMangaNewsAccount();
    }

    /**
     * On récupére les données du planning et si il y a une erreur
     *
     * @private
     * @return void
     */
    private checkMangaNewsAccount(): void {
        if (!Common.checkIsNotUndifined(this.getUserInfos.tokenParsed) ||
            !Common.checkIsNotUndifined(this.getUserInfos.tokenParsed.accountManganews)) {
            return;
        }
        this.usernameMn = this.getUserInfos.tokenParsed.accountManganews.replace(/ /g, '');

        if (!Boolean(this.usernameMn)) {
            return;
        }

        CategoryFunctions.getCrud().getMangaNewsPlanning(this.usernameMn).then((res: any) => {
            this.planningList = res.data;
            this.error = res.error;
        });
    }
}
