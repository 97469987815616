import {ComicsItem} from '@/models/Livres/ComicsItem';
import {categoryTypes} from '@/config/CategoryType';

export const comics: any = {
    name: 'Comics',
    type: categoryTypes.LIVRES_TYPE,
    // même que le nom du fichier
    category: 'comics',
    title: 'Comics',
    className: ComicsItem,
    // nom de la bdd utilisée
    rootLink: '/livres/comics',
    color: 'rgb(26,147,111)',
    phrases: {
        feminin: false,
        noRate: 'Non lu',
        singulier: 'comic',
        pluriel: 'comics',
        deSingulier: 'du comic',
        dePluriel: 'des comics',
        leSingulier: 'le comic',
        lePluriel: 'les comics',
        unSingulier: 'un comic',
        shown: 'comic(s)',
    },
    // type de statistique
    typeStat: 'livres',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        // à partir d'ici : 6 max
        dessinateur: true,
        scenariste: true,
        editeur: true,
        genre: true,
        nbrTome: true,
        nbrTomeLu: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        editeurList: true,
        genreList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
