export const v13: any = {
    version: '1.3',
    subversion: [
        {
            version: '1.3.1',
            date: '06/05/2020',
            content: [
                'Amélioration des listes dans les formulaires et les filtres.',
                'Possibilité de faire un multi tri par liste dans les filtres.',
                'Ajout d\'un nouvel éditeur de BD (Olydri édition).',
                'Corrections mineurs.',
            ],
        },
        {
            version: '1.3.0',
            date: '12/04/2020',
            content: [
                'Amélioration de l\'affichage du partage de collection (mineur).',
                'Ajout du chargement de plus d\'items quand on arrive en bas de la page (10 par 10). Le temps de chargement des pages avec beaucoup d\'items en devient beaucoup plus rapide.',
                'Ajout d\'un bouton "afficher/cacher tous les items".',
                'Optimisation du filtre (filtre plus rapidement).',
                'Création, mise à jour et suppression d\'une fiche sans recharger la page.',
                'Titres cliquables dans les 10 dernières modifications pour accéder directement à la fiche.',
                'Les titres des sites des News sont cliquables pour accéder directement au site.',
                'Mise à jour sécurité des dépendances.',
            ],
        },
    ],
};
