export const v05: any = {
    version: '0.5 bêta',
    subversion: [
        {
            version: '0.5.1b',
            date: '17/04/2019',
            content: [
                'Afficher la liste des utilisateurs ayant un lien de partage.',
                'Ajout du \'filtre avancé\' dans les catégories "Mangas" et "Jeux vidéo".',
                'Tooltip en dessous du lien de partage.',
                'Correction de bugs mineurs.',
            ],
        },
        {
            version: '0.5.0b',
            date: '16/04/2019',
            content: [
                'Valider le formulaire de connection grâce au bouton "Entrer".',
                'Graphique "JEUX VIDÉO PAR CONSOLE" en baton.',
                'Ajout des statistiques de base sur chaque catégorie.',
                'Ajout des couleurs en fonction du statut.',
                'Ajout de la page de changement du mot de passe.',
                'Rechercher si une fiche existe déjà ailleurs avant d\'en créer une.',
                'Ajout des liens de partage de collection.',
                'Ajout de la modal listant les changements des dernières versions.',
            ],
        },
    ],
};
