import {Component, Prop} from 'vue-property-decorator';
import Common from '@/services/Common';
import {BaseScript} from '@/components/BaseScript';


// @ts-ignore
@Component({
    name: 'rssItem',
})
export default class RssItem extends BaseScript {
    @Prop(Object) private readonly item!: any;

    private commonFunc: any = Common;

    constructor() {
        super();
    }

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        if (this.item === undefined) {
            return;
        }

        // préparation du contenu de l'item rss
        if (this.item.enclosure) {
            // formatage de l'url de l'image
            let imgUrl = this.item.enclosure.url;

            // si l'url de l'image n'est pas https
            if (imgUrl.search('https') === -1) {
                imgUrl = imgUrl.replace('http', 'https');
            }
            // on colle l'image à la description
            this.item.content = '<img src="' + imgUrl + '"/>' + this.item.content;
        }

        // on formate le html
        this.item.content = this.item.content.replace(/(\r\n|\n|\r)/gm, '');
        this.item.content = this.item.content.replace('<p>', '');
        this.item.content = this.item.content.replace('</p>', '');
        this.item.content = this.item.content.replace(/<a.*?<\/a>/g, '');

        // on ajoute le lien vers l'article
        this.item.content += ' <a href="' + this.item.link + '" target="_blank">Lire la suite...</a>';
    }
}
