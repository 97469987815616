export const v17: any = {
    version: '1.7',
    subversion: [
        {
            version: '1.7.6',
            date: '18/12/2020',
            content: [
                'Dernère mise à jour de l\'année 2020 !',
                'Afin que le site puisse fonctionner correctement avec les écrans 2K et 4K, le site affichera 20 items ' +
                'au chargement de la page contre 8 auparavent.',
                'Correction d\'un bug de largeur dans le tableau des dernières mises à jour et du planning manga news.',
                'Nettoyage dans le code.',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.7.5',
            date: '11/12/2020',
            content: [
                'Le site est maintenant adapté aux écrans ultra larges (ultrawide screen).',
                'Correction de petits bugs esthétiques.',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.7.4',
            date: '03/12/2020',
            content: [
                'Résolution d\'un bug sur le filtre des extensions.',
                'Mises à jour sécurité.',
            ],
        },
        {
            version: '1.7.3',
            date: '27/11/2020',
            content: [
                'Résolution du bug d\'affichage de l\'image de l\'item dans le visualisateur de fiche.',
                'Amélioration et résolution de bugs dans la l\'outil de recherche globale des fiches.',
                'Mises à jour sécurité.',
                'Optimisation et nettoyage dans le code.',
            ],
        },
        {
            version: '1.7.2',
            date: '13/11/2020',
            content: [
                'Optimisation de la recherche globale des items : 3 caractères minumum pour faire une recherche. Cela permet d\'optimiser le' +
                ' chargement du site.',
                'Correction d\'un bug dans la recherche globale : quand on seletionnait un item et que l\'on se trouvait déjà sur la page' +
                ' de la catégorie, la recherche ne se faisait pas.',
                'Mises à jour sécurité.',
                'Optimisation et nettoyage dans le code.',
            ],
        },
        {
            version: '1.7.1',
            date: '07/11/2020',
            content: [
                'Quand on clique sur un lien dans les dernières mises jour d\'un item n\'étant plus dans la collection' +
                ' (vendu ou emprunté) et qu\'il n\'est plus dans la collection, il s\'affiche de nouveau.',
                'Ajout des états et des statuts dans le visualisateur de fiche.',
                'Dans le planning manga news, ajout de boutons "Profil Manga News" et "Planning Manga News" pour accéder facilement au site.',
                'Problème lié à la fermeture du formulaire des fiches quand on sélectionnait un élément dans une liste, résolu. ' +
                'Il faut maintenant obligatoirement cliquer sur la croix pour fermer le formulaire.',
                'Suite à un bug, les images peuvent de nouveau être uploadées.',
                'Ajout des nouvelles icônes d\'action dans la liste des items en mode tableau.',
                'Petit effet ajouté quand on clique sur le bouton permettant de revenir en haut de la page.',
            ],
        },
        {
            version: '1.7.0',
            date: '02/11/2020',
            content: [
                'Refonte de l\'affichage des items.',
                'Ajout de la visualisation de la fiche d\'un item.',
                'Restructuration du formulaire de création d\'item par onglet.',
                'Ajouts dans la catégorie "Jeux de société" : le nombre de joueur minium et maximum, et du temps d\'une partie.',
                'Ajout dans la barre de navigation, un système de recherche globale des fiches.',
                'Affichage du planning Manga-news-.com (cliquer sur le pseudo dans la barre de navigation) si un pseudo a été saisi dans les paramètres utilisateur.',
                'Le champs "type" dans la section manga a été remplacé par "genre".',
                'La recherche d\'utilisateur à été déplacée dans la barre de navigation dans la liste qui s\'affiche quand on clique sur son pseudo.',
                'Mises à jour sécurité.',
                'Optimisation et nettoyage dans le code.',
            ],
        },
    ],
};
