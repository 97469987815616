import {Vue} from 'vue-property-decorator';
import {ValidationProvider} from 'vee-validate';
import axios from 'axios';
import {commonStrings} from '@/assets/data/commonStrings';

export class BaseScript extends Vue {
    public $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
    };
    protected axios: any = axios;
    protected commonStrings = commonStrings;
}
