import { Component } from 'vue-property-decorator';
import {versions} from '@/components/ModalChangeLog/versions';
import {BaseScript} from '@/components/BaseScript';

@Component({
    name: 'ModalChangeLog',
})
export default class ModalChangeLog extends BaseScript {
    private versionsList: any = versions; // On récupére la liste des logs

    /**
     * On affiche l'index comme titre.
     * Dans le cas de l'index "Bêta", pour éviter les erreurs ASCII dû à l'accent, l'index est sans l'accent.
     * On va donc vérifier qu'il s'agit bien de la clé "Beta", et retourner "Bêta", sinon on retourne directement
     * l'index pour les autre cas.
     *
     * @private
     * @param {string} index
     * @return {string}
     */
    private versionTitle(index: string): string {
        return index === 'beta' ? 'Bêta' : index;
    }
}
