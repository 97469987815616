import {AutresVideosItem} from '@/models/Videos/AutresVideosItem';
import {categoryTypes} from '@/config/CategoryType';

export const autresVideos: any = {
    name: 'Autres videos',
    type: categoryTypes.VIDEOS_TYPE,
    // même que le nom du fichier
    category: 'autresVideos',
    title: 'Autres videos',
    className: AutresVideosItem,
    // nom de la bdd utilisée
    rootLink: '/videos/autres',
    color: 'rgb(255,155,133)',
    phrases: {
        type: 'feminin',
        noRate: 'Non vu',
        singulier: 'vidéo',
        pluriel: 'vidéos',
        deSingulier: 'de la vidéo',
        dePluriel: 'des vidéos',
        leSingulier: 'la vidéo',
        lePluriel: 'les vidéos',
        unSingulier: 'une vidéo',
        shown: 'video(s)',
    },
    // type de statistique
    typeStat: 'basic',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        realisateur: true,
        support: true,
        releaseDate: true,
        studio: true,
        genre: true,
        endDate: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        realisateur: true,
        studio: true,
        endDateList: true,
        genreList: true,
        supportList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
