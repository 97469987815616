import {BdItem} from '@/models/Livres/BdItem';
import {categoryTypes} from '@/config/CategoryType';

export const bd: any = {
    name: 'Bd',
    type: categoryTypes.LIVRES_TYPE,
    // même que le nom du fichier
    category: 'bd',
    title: 'Bd',
    className: BdItem,
    // nom de la bdd utilisée
    rootLink: '/livres/bd',
    color: 'rgb(136,212,152)',
    phrases: {
        feminin: true,
        noRate: 'Non lu',
        singulier: 'bd',
        pluriel: 'bd',
        deSingulier: 'du la bd',
        dePluriel: 'des bd',
        leSingulier: 'la bd',
        lePluriel: 'les bd',
        unSingulier: 'une bd',
        shown: 'bd',
    },
    // type de statistique
    typeStat: 'livres',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        // à partir d'ici : 6 max
        dessinateur: true,
        scenariste: true,
        editeur: true,
        genre: true,
        nbrTome: true,
        nbrTomeLu: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        editeurList: true,
        genreList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
