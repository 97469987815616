import {FilmsItem} from '@/models/Videos/FilmsItem';
import {categoryTypes} from '@/config/CategoryType';

export const films: any = {
    name: 'Films',
    type: categoryTypes.VIDEOS_TYPE,
    // même que le nom du fichier
    category: 'films',
    title: 'Films',
    className: FilmsItem,
    // nom de la bdd utilisée
    rootLink: '/videos/films',
    color: 'rgb(238,96,85)',
    phrases: {
        feminin: false,
        noRate: 'Non vu',
        singulier: 'film',
        pluriel: 'films',
        deSingulier: 'du film',
        dePluriel: 'des films',
        leSingulier: 'le film',
        lePluriel: 'les films',
        unSingulier: 'un film',
        shown: 'film(s)',
    },
    // type de statistique
    typeStat: 'basic',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        realisateur: true,
        support: true,
        releaseDate: true,
        studio: true,
        genre: true,
        endDate: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        realisateur: true,
        endDateList: true,
        studio: true,
        genreList: true,
        supportList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
