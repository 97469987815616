export const v06: any = {
    version: '0.6 bêta',
    subversion: [
        {
            version: '0.6.2b',
            date: '02/06/2019',
            content: [
                'Placement des messages popup en bas de la page.',
                'Problème de redirection de liens lors de l\'ajout ou de la suppression d\'éléments.',
                'Résolution de quelques bugs.',
            ],
        },
        {
            version: '0.6.1b',
            date: '23/05/2019',
            content: [
                'Ajout de la collection "Autres" dans la section "Livres".',
                'Ajout d\'éléments dans les listes "genre" et "éditeur".',
                'Correction de bugs d\'affichage.',
                'Correction de bugs mineurs.',
            ],
        },
        {
            version: '0.6.0b',
            date: '21/05/2019',
            content: [
                'Migration des bases de données google sur nos serveurs.',
                'Amélioration du chercheur des fiches déjà existantes dans les formulaires.',
                'Ajout des dates d\'ajout et de modification.',
                'Ajout du champs indiquant le nom de la personne a qui on a prêté ou vendu.',
                'Il est maintenant possible d\'ajouter plusieurs genres à une fiche.',
                'Ajout des collections Roman, Comics, BD et Artbook.',
                'Ajout des statistiques pour les nouvelles collections.',
            ],
        },
    ],
};
