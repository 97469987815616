import {MusiquesItem} from '@/models/MusiquesItem';
import {categoryTypes} from '@/config/CategoryType';

export const musique: any = {
    name: 'Musique',
    type: categoryTypes.MUSIQUE_TYPE,
    // même que le nom du fichier
    category: 'musique',
    title: 'Musique',
    className: MusiquesItem,
    // nom de la bdd utilisée
    rootLink: '/musique',
    color: 'rgb(255,159,28)',
    phrases: {
        feminin: false,
        noRate: 'Non écouté',
        singulier: 'album',
        pluriel: 'albums',
        deSingulier: 'd\'album',
        dePluriel: 'des albums',
        leSingulier: 'l\'album',
        lePluriel: 'les albums',
        unSingulier: 'un album',
        shown: 'album(s)',
    },
    // type de statistique
    typeStat: 'basic',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        artiste: true,
        label: true,
        support: true,
        genre: true,
        releaseDate: true,
        endDate: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        genreList: true,
        artiste: true,
        endDateList: true,
        label: true,
        langueList: true,
        supportList: true,
        etatList: true,
        statutList: true,
        defautList: true,
    },
};
