export const v12: any = {
    version: '1.2',
    subversion: [
        {
            version: '1.2.1',
            date: '14/03/2020',
            content: [
                'Suppression automatique des images sur le serveur si elle ne sont plus utilisées.',
                'Problème d\'import des images avec un éspace dans le titre résolu.',
                'On peut maintenant bouger l\'image quand on zoom dessus.',
                'Amélioration du design de la modal "DÉTAIL DES MISES À JOUR".',
                'Optimisation du code.',
            ],
        },
        {
            version: '1.2.0',
            date: '08/03/2020',
            content: [
                'Ajout de l\'import d\'image dans la création et la mise à jour d\'une fiche.',
                'Ajout d\'un filtre pour afficher les Vidéos, Jeux vidéo ou Musiques que l\'on a terminé par année.',
                'La fonctionnalité d\'aperçu des images en grand à été améliorée avec la possibilité de zoomer sur l\'image.',
                'Ajout de la fonctionnalité d\'aperçu des images dans "Autres collections".',
                'Mise à niveau des différents packages afin d\'améliorer la sécurité.',
                'Mise à jour sécurité de l\'api.',
                'Changement de l\'image "AUCUNE IMAGE".',
                'Correction bugs mineur et optimisation du code.',
            ],
        },
    ],
};
