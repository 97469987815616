export const v10: any = {
    version: '1.0',
    subversion: [
        {
            version: '1.0.4',
            date: '13/12/2019',
            content: [
                'Grosse optimisation de la base de données.',
                'Adaptation du site à la nouvelle architecture de la base de données.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.0.3',
            date: '20/11/2019',
            content: [
                'Correction de bugs d\'affichages.',
                'Problème d\'orthographe lié au féminin ou masculin du nom de la catégorie sur la génération de certaines phrases.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.0.2',
            date: '13/10/2019',
            content: [
                'Le site devient G-vu.ovh afin de corriger la faute qui éxiste dans le nom depuis sa création.',
                'Ajout d\'un logo.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.0.1',
            date: '11/10/2019',
            content: [
                'Résolution de problèmes d\'affichage.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.0.0',
            date: '09/10/2019',
            content: [
                'Tout le code a été revu et optimisé.',
                'Amélioration du design.',
                'Ajout des catégories "Jeux de société", "Musique" et "Goodies".',
                'Les composants "Filtre" ont été mis en premier dans la colonne de gauche.',
                'Résolution des bugs d\'export.',
                'La page "Statistique" a été retirée et sera refaite entièrement et republiée dans une prochaine version.',
                'Résolution de bugs mineurs.',
            ],
        },
    ],
};
