import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {BaseScript} from '@/components/BaseScript';
import rssItem from '@/components/RssItem/RssItem.vue';

// modules
const general = namespace('general');


@Component({
    name: 'LastNews',
    components: {
        rssItem,
    },
})
export default class LastNews extends BaseScript {
    // actions
    @general.Action('getRss') private getRss!: any;

    private manganews: any = {}; // tableau des données rss
    private gamekult: any = {}; // tableau des données rss
    private cineserie: any = {}; // tableau des données rss

    /**
     * @private
     * @async
     * @return {void}
     */
    private async mounted(): Promise<void> {
        // on récupére les données RSS
        await this.getRss({url: 'https://www.gamekult.com/feed.xml', size: 10}).then((result: any) => this.gamekult = result);
        await this.getRss({url: 'https://www.manga-news.com/index.php/feed/news', size: 10}).then((result: any) => this.manganews = result);
        await this.getRss({url: 'https://www.cineserie.com/feed/', size: 10}).then((result: any) => this.cineserie = result);
    }
}
