import {GoodiesItem} from '@/models/GoodiesItem';
import {categoryTypes} from '@/config/CategoryType';

export const goodies: any = {
    name: 'Goodies',
    type: categoryTypes.GOODIES_TYPE,
    // même que le nom du fichier
    category: 'goodies',
    title: 'Goodies',
    className: GoodiesItem,
    // nom de la bdd utilisée
    rootLink: '/goodies',
    color: 'rgb(255,209,102)',
    phrases: {
        feminin: false,
        noRate: 'Aucune note',
        singulier: 'goodie',
        pluriel: 'goodies',
        deSingulier: 'du goodie',
        dePluriel: 'des goodies',
        leSingulier: 'le goodie',
        lePluriel: 'les goodies',
        unSingulier: 'un goodie',
        shown: 'goodie(s)',
    },
    // type de statistique
    typeStat: 'notInteractive',
    // données affichées dans le component item
    itemData: {
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        fabricant: true,
        prix: true,
        monnaie: true,
        oeuvre: true,
        type: true,
        releaseDate: true,
        genre: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        fabricant: true,
        oeuvre: true,
        genreList: true,
        statutList: true,
        etatList: true,
        typeList: true,
        defautList: true,
    },
};
