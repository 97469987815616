import {JeuxSocieteItem} from '@/models/Jeux/JeuxSocieteItem';
import {categoryTypes} from '@/config/CategoryType';

export const jeuxSociete: any = {
    name: 'Jeux de société',
    type: categoryTypes.JEUX_TYPE,
    // même que le nom du fichier
    category: 'jeuxSociete',
    title: 'Jeux de société',
    className: JeuxSocieteItem,
    // nom de la bdd utilisée
    rootLink: '/jeux/jeuxdesociete',
    color: 'rgb(0,119,182)',
    phrases: {
        feminin: false,
        noRate: 'Non joué',
        singulier: 'jeu de société',
        pluriel: 'jeux de société',
        deSingulier: 'du jeu de société',
        dePluriel: 'des jeux de société',
        leSingulier: 'le jeu de société',
        lePluriel: 'les jeux de société',
        unSingulier: 'un jeu de société',
        shown: 'jeu(x) de société',
    },
    // type de statistique
    typeStat: 'notInteractive',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        extension: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        auteur: true,
        editeur: true,
        type: true,
        minPlayer: true,
        tempsPartie: true,
        releaseDate: true,
        genre: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        extension: true,
        minPlayer: true,
        maxPlayer: true,
        tempsPartie: true,
        auteur: true,
        editeurSearch: true,
        editeurList: true,
        genreList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        typeList: true,
        defautList: true,
    },
};
