import {ConsolesItem} from '@/models/Jeux/ConsolesItem';
import {categoryTypes} from '@/config/CategoryType';

export const consoles: any = {
    name: 'Consoles & Accessoires',
    type: categoryTypes.JEUX_TYPE,
    // même que le nom du fichier
    category: 'consoles',
    title: 'Consoles & Accessoires',
    className: ConsolesItem,
    // nom de la bdd utilisée
    rootLink: '/jeux/consoles',
    color: 'rgb(3,4,94)',
    phrases: {
        feminin: true,
        noRate: 'Non testé',
        singulier: 'console ou accessoire',
        pluriel: 'consoles et/ou accessoires',
        deSingulier: 'de la console ou de l\'accessoire',
        dePluriel: 'des consoles et/ou des accessoires',
        leSingulier: 'la console ou l\'accessoire',
        lePluriel: 'les consoles et/ou les accessoires',
        unSingulier: 'une console ou un accessoire',
        shown: 'console(s) et/ou accessoire(s)',
    },
    // type de statistique
    typeStat: 'notInteractive',
    // données affichées dans le component item
    itemData: {
        origine: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        console: true,
        constructeur: true,
        type: true,
        releaseDate: true,
        edition: true,
        support: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        consoleList: true,
        constructeurList: true,
        editionList: true,
        origineList: true,
        supportList: true,
        typeList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
