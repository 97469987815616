export const v14: any = {
    version: '1.4',
    subversion: [
        {
            version: '1.4.3',
            date: '09/06/2020',
            content: [
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.4.2',
            date: '31/05/2020',
            content: [
                'Résolution d\'un bug fonctionnel dans la modal des mises à jour.',
                'Mise à jour de certaines dépendances.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.4.1',
            date: '22/05/2020',
            content: [
                'Amélioration de la pertinence des statistiques des livres.',
                'Le graphique sur la page d\'accueil affiche le nombre de tomes pour les livres.',
                'Bug graphique sur la barre de navigation résolu.',
                'Résolution de bugs mineurs.',
            ],
        },
        {
            version: '1.4.0',
            date: '21/05/2020',
            content: [
                'Refonte de toute la partie partage de collection : on peut maintenant visiter une collection comme si on était sur son propre compte.',
                'Mise à jour sécurité des dépendances.',
                'Optimisation du code',
                'Résolution de bugs mineurs.',
            ],
        },
    ],
};
