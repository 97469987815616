import {VideoGamesItem} from '@/models/Jeux/VideoGamesItem';
import {categoryTypes} from '@/config/CategoryType';

export const videoGames: any = {
    name: 'Jeux Vidéo',
    type: categoryTypes.JEUX_TYPE,
    // même que le nom du fichier
    category: 'videoGames',
    title: 'Jeux Vidéo',
    className: VideoGamesItem,
    // nom de la bdd utilisée
    rootLink: '/jeux/jeuxvideo',
    color: 'rgb(3,4,94)',
    phrases: {
        feminin: false,
        noRate: 'Non joué',
        singulier: 'jeu vidéo',
        pluriel: 'jeux vidéo',
        deSingulier: 'du jeu vidéo',
        dePluriel: 'des jeux vidéo',
        leSingulier: 'le jeu vidéo',
        lePluriel: 'les jeux vidéo',
        unSingulier: 'un jeu vidéo',
        shown: 'jeu(x) vidéo',
    },
    // type de statistique
    typeStat: 'jeuxvideo',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        dematerialise: true,
        extension: true,
        note: true,
        externalLink: true,
        defaut: true,
        // à partir d'ici : 6 max
        console: true,
        editeur: true,
        developpeur: true,
        releaseDate: true,
        genre: true,
        endDate: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        consoleList: true,
        extension: true,
        dematerialise: true,
        editeurSearch: true,
        genreList: true,
        endDateList: true,
        editeurList: true,
        developpeur: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
