import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {categoryTypes} from '@/config/CategoryType';

import {BaseScript} from '@/components/BaseScript';
import TableLastUpdate from '@/components/Home/LastUpdate/TableLastUpdate.vue';

// modules
const general = namespace('general');


@Component({
    name: 'LastUpdate',
    components: {
        TableLastUpdate,
    },
})
export default class LastUpdate extends BaseScript {
    // actions
    @general.Action('setChangeLog') private setChangeLog!: any;

    private categoryType: any = categoryTypes;

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        // on initialise les 10 dernières mise à jour
        this.setChangeLog();
    }
}
