import {artbook} from '@/config/CategoryFiles/Livres/artbook';
import {autresLivres} from '@/config/CategoryFiles/Livres/autresLivres';
import {bd} from '@/config/CategoryFiles/Livres/bd';
import {comics} from '@/config/CategoryFiles/Livres/comics';
import {romans} from '@/config/CategoryFiles/Livres/romans';
import {mangas} from '@/config/CategoryFiles/Livres/mangas';

import {films} from '@/config/CategoryFiles/Videos/films';
import {series} from '@/config/CategoryFiles/Videos/series';
import {autresVideos} from '@/config/CategoryFiles/Videos/autresVideos';

import {consoles} from '@/config/CategoryFiles/Jeux/consoles';
import {videoGames} from '@/config/CategoryFiles/Jeux/videoGames';
import {jeuxSociete} from '@/config/CategoryFiles/Jeux/jeuxSociete';

import {musique} from '@/config/CategoryFiles/musique';
import {goodies} from '@/config/CategoryFiles/goodies';

export const config: any = {
    artbook,
    autresLivres,
    bd,
    comics,
    mangas,
    romans,
    films,
    series,
    autresVideos,
    consoles,
    videoGames,
    jeuxSociete,
    musique,
    goodies,
};
