import {MangaItem} from '@/models/Livres/MangaItem';
import {categoryTypes} from '@/config/CategoryType';

export const mangas: any = {
    name: 'Mangas',
    type: categoryTypes.LIVRES_TYPE,
    // même que le nom du fichier
    category: 'mangas',
    title: 'Mangas',
    className: MangaItem,
    // nom de la bdd utilisée
    rootLink: '/livres/mangas',
    color: 'rgb(17,75,95)',
    phrases: {
        feminin: false,
        noRate: 'Non lu',
        singulier: 'manga',
        pluriel: 'mangas',
        deSingulier: 'du manga',
        dePluriel: 'des mangas',
        leSingulier: 'le manga',
        lePluriel: 'les mangas',
        unSingulier: 'un manga',
        shown: 'manga(s)',
    },
    // type de statistique
    typeStat: 'livres',
    // données affichées dans le component item
    itemData: {
        langue: true,
        statut: true,
        etat: true,
        note: true,
        externalLink: true,
        // à partir d'ici : 6 max
        dessinateur: true,
        scenariste: true,
        editeur: true,
        genre: true,
        nbrTome: true,
        nbrTomeLu: true,
    },
    // liste des filtres utilisés
    filterList: {
        title: true,
        note: true,
        editeurList: true,
        genreList: true,
        langueList: true,
        statutList: true,
        etatList: true,
        defautList: true,
    },
};
