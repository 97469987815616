
    import {Component, Vue} from 'vue-property-decorator';
    import LoadingStatus from '@/components/Loading/Loading.vue';
    import {namespace} from 'vuex-class';

    const general = namespace('general');

    @Component({
        components: {LoadingStatus},
    })
    export default class App extends Vue {
        @general.Action('initUserInfos') private readonly initUserInfos!: any;
        @general.Getter('getToastData') private readonly getToastData!: any;

        private mounted() {
            this.initUserInfos(Vue.prototype.$keycloak);
        }
    }
