import { Component } from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';
import {CategoryFunctions} from '@/services/CategoryFunctions';
import {Crud} from '@/services/Crud';
import {namespace} from 'vuex-class';

// modules
const general = namespace('general');

@Component({
    name: 'ModalReportBug',
})
export default class ModalReportBug extends BaseScript {
    private message: string = '';
    private sent: boolean = false;
    private buttonDisable: boolean = false;
    private compteur: number = 10;

    // getters
    @general.Getter('getUserInfos') private readonly getUserInfos!: any;

    /**
     * Permet d'envoyer un email
     *
     * @private
     * @return {Promise<void>}
     */
    private async submit(): Promise<void> {
        // on vérifie si un message a été saisi
        if (this.message === '') {
            return;
        }

        // on désactive le bouton
        this.buttonDisable = true;

        // On envoie l'email
        await CategoryFunctions.getCrud().sendEmail(Crud.EMAIL_TYPE_REPORT, this.message, this.getUserInfos.userName).then((res: any) => {
            // On indique que le message a été envoyé
            this.sent = res;

            // on active le compteur avant que l'on puisse renvoyer un message
            const interval = setInterval(() => this.compteur--, 1000);

            setTimeout(() => {
                // on réactive le formulaire
                this.sent = false;
                this.buttonDisable = false;

                // on clear le message
                this.message = '';

                // on clear le compteur
                clearInterval(interval);
                this.compteur = 10;
            }, 10000);
        });
    }
}
