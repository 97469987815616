import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';

import {BaseScript} from '@/components/BaseScript';

// modules
const general = namespace('general');
@Component({
    name: 'userList',
})
export default class UserList extends BaseScript {
    private usersList: any = []; // liste des utilisateurs
    private selectedUser: any = null;

    // getters
    @general.Getter('getSharedUserList') private readonly getSharedUserList!: any;

    constructor() {
        super();
    }

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        // on récupére la liste des utilisateurs
        this.getSharedUserList.then((result: any) => {
            for (const item in result) {
                if (result[item]) {
                    this.usersList.push(result[item]);
                }
            }
        });
    }

    /**
     * Redirige vers le profile de l'utilisateur sélectionné
     *
     * @return {void}
     */
    @Watch('selectedUser', {deep: true})
    private goToProfile(): void {
        this.$router.push({name: 'userRefresh', params: {username: this.selectedUser.username}});
    }
}
