// version 1
import {v18} from '@/components/ModalChangeLog/versions/v1/v1.8';
import {v17} from '@/components/ModalChangeLog/versions/v1/v1.7';
import {v16} from '@/components/ModalChangeLog/versions/v1/v1.6';
import {v15} from '@/components/ModalChangeLog/versions/v1/v1.5';
import {v14} from '@/components/ModalChangeLog/versions/v1/v1.4';
import {v13} from '@/components/ModalChangeLog/versions/v1/v1.3';
import {v12} from '@/components/ModalChangeLog/versions/v1/v1.2';
import {v11} from '@/components/ModalChangeLog/versions/v1/v1.1';
import {v10} from '@/components/ModalChangeLog/versions/v1/v1.0';

// versions bêta
import {v07} from '@/components/ModalChangeLog/versions/beta/v0.7';
import {v06} from '@/components/ModalChangeLog/versions/beta/v0.6';
import {v05} from '@/components/ModalChangeLog/versions/beta/v0.5';

export const versions: any = {
    // versions 1
    'Version 1': [
        v18,
        v17,
        v16,
        v15,
        v14,
        v13,
        v12,
        v11,
        v10,
    ],

    // versions bêta
    'beta': [
        v07,
        v06,
        v05,
    ],

};
