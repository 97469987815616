export const v07: any = {
    version: '0.7 bêta',
    subversion: [
        {
            version: '0.7.3b',
            date: '07/09/2019',
            content: [
                'Bug sur les exports CSV résolu.',
                'La redirection vers la fiche crée ou mise à jour fonctionne de nouveau.',
                'Bugs mineurs.',
            ],
        },
        {
            version: '0.7.2b',
            date: '02/09/2019',
            content: [
                'Optimisation des performances.',
                'Ajout du statut "suppression" dans la liste des mises à jour.',
            ],
        },
        {
            version: '0.7.1b',
            date: '26/08/2019',
            content: [
                'Mise en place de la nouvelle interface de partage de collection.',
                'Amélioration de la bar de chargement.',
                'Optimisation et bugs mineurs.',
            ],
        },
        {
            version: '0.7.0b',
            date: '19/08/2019',
            content: [
                'Meilleure protection des données.',
                'Déplacement des statistiques sur une page spécifique.',
                'Affichage d\'actualités sur le dashboard.',
                'Affichage des dernières modifications sur le dashboard.',
                'Mise en place d\'un bloc note sur le dashboard.',
                'Ajout des catégories vidéos : Films, Séries et Autres.',
                'La bar de chargement a été remise en place.',
                'Suppression des pop-up lors de l\'arrivée sur le site.',
                'Résolution du bug de filtre Xbox/Xbox 360/Xbox One dans la catégorie Jeux vidéo.',
                'Amélioration des performances du site.',
            ],
        },
    ],
};
